/**
 * https://chir.ag/projects/name-that-color/#14558E
 */
class Swatch {
  static slate = '#656565'
  static burntOrange = '#d14800'
  static matisse = '#14558e'
  static green = '#066a06'
}

export default Swatch
